import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockClosed from "../images/padlock-closed.svg"
import axios from "axios"
import {
  ALERT_MSG_ERROR, ALERT_MSG_INFO,
  AUTH_TOKEN_KEY,
  EMAIL_SUPPORT,
  PARAM_CARD_NMBRE,
  PARAM_EMAIL,
  PARAM_RECOVER_UNIQUE_CODE, translate,
  URL_LOGIN, URL_RECOVER_ACCOUNT
} from "../utils"
import ModalAlert from "../components/modalAlert"

class Login extends Component {
  state = {
    uniqueCode: '',
    email: '',
    password: '',
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    placeholderNewPassword: '',
  }

  componentDidMount = async () => {
    this.setState({
      placeholderNewPassword: translate('nieuw wachtwoord')
    })
    if (this.props.location) {
      const query = new URLSearchParams(this.props.location.search);
      if (query.get(PARAM_RECOVER_UNIQUE_CODE) !== null) {
        this.setState({uniqueCode: query.get(PARAM_RECOVER_UNIQUE_CODE)})
        if (query.get(PARAM_EMAIL) !== null) {
          this.setState({email: query.get(PARAM_EMAIL)})
        }
      }else {
        this.setState({
          showAlert: true,
          alertTitle: ALERT_MSG_ERROR,
          alertMessage: `${translate('unieke code niet gevonden, neem contact op met')} ${EMAIL_SUPPORT}`,
        })
      }
    }
  }

  pressSubmit = (e) => {
    e.preventDefault();
    const {uniqueCode,email,password} = this.state;
    const data = {
      recoveryUniqueCode: uniqueCode,
      email,
      password
    }
    axios.post(URL_RECOVER_ACCOUNT,data)
      .then(res => {
        if (res.data) {
          console.log(res.data)
        }
        this.setState({
          showAlert: true,
          alertTitle: ALERT_MSG_INFO,
          alertMessage: translate('gefeliciteerd, uw wachtwoord is succesvol bijgewerkt, u wordt binnen 5 seconden doorgestuurd naar de inlogpagina'),
        })
        setTimeout(this.redirectToLoginPage,1000);
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
          this.setState({
            showAlert: true,
            alertTitle: ALERT_MSG_ERROR,
            alertMessage: err.response.data.message,
          })
        }
        console.log(err)
      });

  }

  redirectToLoginPage = () => {
    window.location.href = ('/login');
  }

  render() {
    const {password,showAlert,alertMessage,alertTitle} = this.state;

    return (
      <Layout withBg>
        <div className="formWrapper">
          <Container>
            <Row className="justify-content-center">
              <Col md="12" lg="6" xl="4">
                <div className="text-left">
                  <h2 className="mb-3">{translate('vul uw nieuwe wachtwoord in')}</h2>
                  <Form onSubmit={e => this.pressSubmit(e)}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          <img
                            width={11}
                            height={17}
                            className="img-fluid"
                            src={padlockClosed}
                            alt=""
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type={'password'}
                        value={password}
                        onChange={(e) => this.setState({password: e.target.value})}
                        placeholder={this.state.placeholderNewPassword}
                        aria-label=""
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                    <div style={{display: 'flex',justifyContent: 'center'}}>
                      <Button variant="success" type="submit" className="button-yellow" /*onClick={(e) => this.pressSubmit(e)}*/>
                        {translate('opslaan')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {(showAlert)?
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => this.setState({showAlert: false})}
          />:null
        }
      </Layout>
    )
  }
}

export default Login
